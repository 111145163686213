import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import logo1 from "../img/positive_life_logo.png"
import logo2 from "../img/positive-health-logo.png"
import logo3 from "../img/body_health_and_fitness_logo.png"
import logo4 from "../img/health_and_life_logo.png"
import logo10 from "../img/life-is-positive.png"

import SiteInfo from "./siteinformation"

const Footer = ({ className }) => {

    return (

        <footer class={className}>
            <div class="level">
                <div class="level-left">

                    <div className="footer-logo">
                        <img src={getHeaderLogo()} alt=""/>
                    </div>


                </div>
                <div class="level-right">
                </div>
            </div>
            <div class="level">
                <div class="level-left">
                </div>
                <div class="level-right">
                    <a href="/">Home</a> |&nbsp;
                    <a href="/earnings/">Earnings</a> |&nbsp;
                    <a href="/privacy/">Privacy</a> |&nbsp;
                    <a href="/terms/">Terms and Conditions</a> |&nbsp;
                    <a href="/dmca/">DMCA</a>
                </div>
            </div>
            <div class="level">
                <div class="level-left">
                </div>
                <div class="level-right">
                    <p
                        className="copyright">
                        Copyright &copy; 2020
                        <script>document.write(new Date().getFullYear());</script>
                      { getSiteName() } All rights reserved

                    </p>
                </div>
            </div>
        </footer>

    )

}

function getSiteName() {
  var site = new SiteInfo().getSiteNumber();

  if (site == 1) {
    return "Life Is Positive";
  }
}

function getHeaderLogo() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return logo2;
    }
    if (site == 3) {
        return logo3;
    }
    if (site == 4) {
        return logo4;
    }

    if (site == 10) {
        return logo10;
    }
    return logo1;
}


export default Footer



