import PropTypes from "prop-types"
import React from "react"
import logo1 from "../img/positive_life_logo.png"
import logo2 from "../img/positive-health-logo.png"
import logo3 from "../img/body_health_and_fitness_logo.png"
import logo4 from "../img/body_health_and_life.png"

import SiteInfo from "./siteinformation"
import Helmet from "react-helmet"

const facebookLink = new SiteInfo().getFacebookPage()

const Header = ({ siteTitle }) => (

<nav className="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      {getSiteNumber() !== 10 &&
      <div className="navbar-brand">
        <a href="/" className="navbar-item">
          <img src={getHeaderLogo()} alt="" className="navLogo"/>
        </a>
      </div>
      }
    <div className="navbar-menu">
        <div className="navbar-end">
            <a className="navbar-item" href="/">Home</a>
            <a className="navbar-item" href="/earnings/">Earnings</a>
            <a className="navbar-item" href="/privacy/">Privacy</a>
            <a className="navbar-item" href="/terms/">Terms and Conditions</a>
            <a className="navbar-item" href="/dmca/">DMCA</a>
        </div>
    </div>
    </div>
</nav>
   /* <Helmet>
        {/!* inline style elements *!/}
        { getSiteNumber() == 1 &&
        <style type="text/css">{`
        .recipe .recipe-info:after {

            height: 0;
            background: #000;
        }
        .recipe .recipe-info, .header-top {
            background: #242582;
            color: white;

        }

    `}

        </style> }
        { getSiteNumber() == 4 &&
        <style type="text/css">{`
        .recipe .recipe-info:after {

            height: 0;
            background: #3D017A;
        }
        .recipe .recipe-info, .header-top {
            background: #3D017A;
            color: white;

        }

    `}

        </style> }
    </Helmet>
*/

)

function getHeaderLogo() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return logo2;
    }
    if (site == 3) {
        return logo3;
    }
    if (site == 4) {
        return logo4;
    }

    return logo1;
}

function getSiteNumber() {
    var site = new SiteInfo().getSiteNumber();
    return site;
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
