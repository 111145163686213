/**
 * Created by nick on 1/11/2020.
 */

class SiteInfo {
    getSiteNumber() {
      const site = process.env.GATSBY_SITE;
      if (site == 'lifeispositivesite.com') {
        return 10;
      }
      if (site == 'positivelife.link') {
        return 1;
      }
      if (site == 'positivehealth.link') {
        return 2;
      }
      if (site == 'bodyhealthandfitness.com') {
        return 3;
      }
        if (site == 'healthandlife.link') {
            return 4;
        }

        if (site == 'lifeispositivesite.com') {
          return 10;
        }
      return 1;
    }

    getFacebookPage() {
        if (this.getSiteNumber() == 1) {
            return "https://facebook.com/positivelifesite"
        }
      if (this.getSiteNumber() == 2) {
        return "https://facebook.com/positivehealthlink"
      }
      if (this.getSiteNumber() == 3) {
        return "https://facebook.com/bodyhealthandfitness"
      }
        if (this.getSiteNumber() == 4) {
            return "https://facebook.com/healthandlifesite"
        }
        if (this.getSiteNumber() == 10) {
          return "https://facebook.com/lifeispositivesite"
        }
    }

    getPageTitle() {
        if (this.getSiteNumber() == 1) {
            return "Positive Life"
        }
        if (this.getSiteNumber() == 2) {
            return "Positive Health"
        }
        if (this.getSiteNumber() == 3) {
            return "Body Health and Fitness"
        }
        if (this.getSiteNumber() == 4) {
            return "Health And Life"
        }
         if (this.getSiteNumber() == 10) {
            return "Life Is Positive"
        }
    }

    getSiteEmail() {
        if (this.getSiteNumber() == 2) {
            return "";
        }
        if (this.getSiteNumber() == 3) {
            return "";
        }
        if (this.getSiteNumber() == 4) {
            return "admin@bodyhealthandlife.com";
        }
        if (this.getSiteNumber() == 5) {
            return "";
        }
        if (this.getSiteNumber() == 10) {
            return "admin@lifeispositivesite.com";
        }
        return "";
    }

    getIfRenderPost(categories) {
        var site = new SiteInfo().getSiteNumber();
        var catType = "- A";
        if (site === 2) {
            catType = "- B";
        }
        if (site === 3) {
            catType = "- C";
        }
        if (site === 4) {
            catType = "- A";
        }
        if (site === 5) {
            catType = "- B";
        }
        if (site === 10) {
          catType = "- Positive";
        }
        var checkArray = categories.find( (val) => {
            if (val.name && val.name.includes(catType)) {
                return true;
            }
            return false;
        });
        if (checkArray) {
            return true;
        }
        return false;
    }
}

export default SiteInfo